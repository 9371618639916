import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCXGgt4eJ_A6W-7CrXIBgp4UYoLAC9xsQk",
    authDomain: "iylus-308807.firebaseapp.com",
    databaseURL: "https://iylus-308807-default-rtdb.firebaseio.com",
    projectId: "iylus-308807",
    storageBucket: "iylus-308807.appspot.com",
    messagingSenderId: "128458060095",
    appId: "1:128458060095:web:10aaebe16651c07079638a",
    measurementId: "G-CX82WC8T0J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };