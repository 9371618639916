import { AgoraVideoPlayer } from "agora-rtc-react";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import './chat.css'

export default function Video(props) {
  const { users, tracks } = props;
  const [gridSpacing, setGridSpacing] = useState(12);

  useEffect(() => {
    // Update grid spacing dynamically based on the number of users
    if (users.length > 1) {
      setGridSpacing(Math.max(Math.floor(12 / users.length), 4));
    } else {
      setGridSpacing(12); // Full screen for the first user
    }
  }, [users]);

  return (
    <Grid container style={{ height: "100%", overflow: "hidden" }} spacing={2}>
      {users.length > 0 &&
        users.map((user, index) => {
          if (user.videoTrack) {
            return (
              <Grid
                item
                xs={index === 0 && users.length === 1 ? 12 : gridSpacing}
                key={user.uid}
              >
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  style={{ height: "100%", width: "100%" }}
                  className="agoraVideo"
                />
              </Grid>
            );
          } else return null;
        })}
    </Grid>
  );
}
