import React, { useState, useEffect, useRef } from 'react';
import { database } from './firebase'; // Adjust the import path if necessary
import { ref, onValue, push, set } from "firebase/database";
import Avatar from '@material-ui/core/Avatar';
import './chat.css'
import ChatIcon from '../src/assets/images/chat.svg' 

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const currentUser = "Agent"; 
  const messagesEndRef = useRef(null);
  const prevMessagesRef = useRef([]);
  const contactNumber = window.$contactNumber  // Use a default value if contactNumber is not available
  const messagesRef = ref(database, `/groups/${contactNumber}`);

  // Function to scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Listening for changes in the database and updating messages state
  useEffect(() => {
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messagesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setMessages(messagesList);
    });

    return () => unsubscribe();
  }, [messagesRef]);

  // Scroll to the bottom whenever new messages are detected
  useEffect(() => {
    if (prevMessagesRef.current.length !== messages.length) {
      scrollToBottom();
    }
    prevMessagesRef.current = messages;
  }, [messages]);

  // Function to handle sending a message
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const newMessageRef = push(messagesRef);
    try {
      await set(newMessageRef, {
        message: newMessage,
        name: currentUser,
        timestamp: Date.now()  // Add a timestamp for sorting or future use
      });
      setNewMessage('');
    } catch (error) {
      console.error("Error writing new message to Firebase Database", error);
    }
  };

  // Function to handle key press event
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  }
  

  return (
    <div className='main-chat' style={{ flex: 1, padding: '10px', backgroundColor: '#f9f9f9', width: '98%', marginTop:'50px', height:'92.5%'}}>
      <div style={{ maxHeight: '70vh', overflowY: 'auto', scrollbarWidth:'none' }}>
        {messages.map((message) => (
          <>
          <div 
            key={message.id} 
            style={{ 
              display: 'flex', 
              flexDirection: message.name === currentUser ? 'row-reverse' : 'row', 
              alignItems: 'center', 
              marginBottom: '10px' 
            }}
          >
            <Avatar alt={message.name} src={`https://api.adorable.io/avatars/40/${message.name}.png`} />
            <div 
              style={{ 
                backgroundColor: message.name === currentUser ? '#DCECFA' : '#103a5e', 
                padding: '10px', 
                borderRadius: '10px', 
                marginLeft: message.name === currentUser ? '0' : '10px', 
                marginRight: message.name === currentUser ? '10px' : '0', 
                color: message.name === currentUser ? 'black' : 'white', 
                borderBottomLeftRadius: '0'
              }}
            >
              <p><strong>{message.name}:</strong> {message.message}</p>
            </div>
          </div>
          </>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div style={{ position: 'relative', width: '100%' }}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ width: '95%', padding: '10px', paddingRight: '40px', marginBottom: '10px', borderRadius:"20px", fontFamily:'system-ui' }}
          placeholder='Send message'
        />
        <img
          onClick={handleSendMessage}
          src={ChatIcon}
          style={{ 
            position: 'absolute', 
            right: '2px', 
            top: '40%', 
            transform: 'translateY(-50%)', 
            cursor: 'pointer',
            height: '24px',
            width: '24px'
          }}
        />
      </div>
    </div>
  );
};

export default Chat;