import { useState, useEffect } from "react";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
  channelName,
} from "./settings.js";
import { Grid } from "@material-ui/core";
import Video from "./Video";
import Controls from "./Controls";
import { useLocation } from 'react-router-dom';

export default function VideoCall(props) {
  const { setInCall } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  window.$contactno = null;
    // return params.get('channelName') || "+923355127188";
    useEffect(() => {
      // Mute audio after tracks are published
      if (start && tracks) {
        tracks[0].setEnabled(false); // Mute audio after publishing
      }
    }, [start, tracks]);
  useEffect(() => {

    const pathname = window.location.pathname;
    // Extract the contact number from the pathname
    const contactNumber = pathname.substring(1); // Remove the leading '/'
    // Log the contact number to the console
    window.$contactNumber = contactNumber;
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      try {
        await client.join(config.appId, name, config.token, null);
      } catch (error) {
        console.log("error");
      }

      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      try {

        init(window.$contactNumber);
      } catch (error) {
        console.log(error);
      }
    }
  }, [window.$contactNumber, client, ready, tracks]);

  
  return (
    <Grid container direction="column" style={{ height: "100%" }}>
      <Grid item style={{ height: "5%",position:'relative',top:'10px' }}>
        {ready && tracks && (
          <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
        )}
      </Grid>
      <Grid item style={{ height: "95%" }}>
        {start && tracks && <Video tracks={tracks} users={users} />}
      </Grid>
    </Grid>
  );
}
