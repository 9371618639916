import React, { useState } from 'react';
import VideoCall from "./VideoCall";
import Chat from "./Chat";
import "./chat.css"
import ScreenRecorder from './ScreenRecorder';

function App() {
  const [inCall, setInCall] = useState(true); // Automatically join the call

  return (
    <div className="App" style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "1.5rem 0" }}>
        <ScreenRecorder/>
      </div>
      <div style={{ flex: 1, display: "flex" }}>
        <div className='video-main' style={{ flex: 1 }}>
          <VideoCall setInCall={setInCall} />
        </div>
        <div className='main-chat' style={{ flex: 1 }}>
          <Chat />
        </div>
      </div>
    </div>
  );
}

export default App;
