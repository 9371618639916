import React, { useState, useRef } from 'react';
import "./chat.css"

const ScreenRecorder = () => {
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [stream, setStream] = useState(null);

    const startRecording = async () => {
        const displayMediaOptions = {
            video: true,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                sampleRate: 44100,
            }
        };

        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
                audio: true, // Request system audio capture
            });

            // Combining system audio with microphone audio
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const combinedStream = new MediaStream([
                ...screenStream.getVideoTracks(),
                ...screenStream.getAudioTracks(), // Adding system audio
                ...audioStream.getAudioTracks() // Adding microphone audio
            ]);

            setStream(combinedStream);
            mediaRecorderRef.current = new MediaRecorder(combinedStream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                const blob = new Blob([event.data], { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'iyzilUserRecording.webm';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            };

            mediaRecorderRef.current.start();
            setRecording(true);
        } catch (err) {
            if (err.name === 'NotAllowedError') {
                alert('Screen capture permission was denied. Please allow screen capture.');
            } else {
                console.error("Error: " + err);
            }
        }
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        stream.getTracks().forEach(track => track.stop());
        setRecording(false);
    };

    return (
        <div>
            <button className='screenRecorder' onClick={recording ? stopRecording : startRecording}>
                {recording ? 'Stop Recording' : 'Start Recording'}
            </button>
        </div>
    );
};

export default ScreenRecorder;
